import React, { useState, useEffect } from 'react';
import logo from '../../assets/rekebisha-logo-reverse 1.png'
import { auth } from '../../Database/RekebishaAdmin/config';
import UserProfile from '../UserProfile';

export default function MainHeader() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleClick = (event, url) => {
    event.preventDefault();
    window.open(url, '_blank');
  };

  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <header>
      <nav className="bg-[#173044] border-gray-200 px-4 lg:px-6 py-2 dark:bg-gray-800">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl py-2">
          <a href="/" className="flex items-center">
            <img src={logo} className="h-10 sm:h-12" alt="Rekebisha Logo" />
          </a>
          <div className="flex items-center lg:order-2">
            {isLoggedIn ?
              <UserProfile />
              :
              <div className='flex flex-row items-center'>
                <a href="/login" className="bg-white hover:bg-red-500 hover:text-white text-black focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2">Log in</a>
              </div>
            }
            <button data-collapse-toggle="mobile-menu-2" type="button" className="inline-flex items-center p-2 ml-1 text-sm text-white hover:text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
              <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
          </div>
          <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-1 lg:mt-0">
              <li>
                <a href="/" className="block py-2 pr-4 pl-3 text-white rounded text-sm" aria-current="page">Home</a>
              </li>
              <li>
                <a href="/find-fundi" className="block py-2 pr-4 pl-3 text-white rounded text-sm" aria-current="page">Find Fundi</a>
              </li>
              <li>
                <a href="#" className="block py-2 pr-4 pl-3 text-white rounded text-sm" aria-current="page"
                 onClick={(event) =>
                handleClick(
                  event,
                  'https://firebasestorage.googleapis.com/v0/b/rekebishaapp.appspot.com/o/Tutorials%2FJUA%20KALI%20(7).mp4?alt=media&token=06768701-b8ea-4fca-808f-60373781cd4a'
                )
              }
                >Jua Kali</a>
              </li>
              <li>
                <a href="#" className="block py-2 pr-4 pl-3 text-white rounded text-sm" aria-current="page"
                 onClick={(event) =>
                handleClick(
                  event,
                  'https://firebasestorage.googleapis.com/v0/b/rekebishaapp.appspot.com/o/Tutorials%2FJUA%20KALI%20(6).mp4?alt=media&token=be15ae97-92ce-4a5c-a651-6e3c606d3f4d'
                )
              }
                >DotDot</a>
              </li>
              <li>
                <a href="/find-rental" className="block py-2 pr-4 pl-3 text-white rounded text-sm" aria-current="page">Find Property</a>
              </li>
              <li>
                <a href="/about" className="block py-2 pr-4 pl-3 text-white rounded text-sm" aria-current="page">About Us</a>
              </li>
              <li>
                <a href="/faqs" className="block py-2 pr-4 pl-3 text-white rounded text-sm" aria-current="page">FAQs</a>
              </li>
              <li>
                <a href="/contact-us" className="block py-2 pr-4 pl-3 text-white rounded text-sm" aria-current="page">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
