import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";


const firebaseConfigP = firebase.initializeApp({
    apiKey: "AIzaSyCd_BsPt7009SjeVlyKGDi3GqJGtqnZvXk",
    authDomain: "rentalmanagementsystem-1ad2b.firebaseapp.com",
    projectId: "rentalmanagementsystem-1ad2b",
    storageBucket: "rentalmanagementsystem-1ad2b.firebasestorage.app",
    messagingSenderId: "522161036039",
    appId: "1:522161036039:web:9aacb28b49a13b41cec3a2",
    measurementId: "G-7VL6BQT43Y"
}, 'firebaseConfigP');


// Firebase Config for Rekebisha Website
export const db = firebaseConfigP.firestore();
export const auth = firebaseConfigP.auth();
export const storage = firebaseConfigP.storage();