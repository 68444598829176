import { useState, useEffect } from 'react';
import MainFooter from "../../Components/Footer/MainFooter";
import MainHeader from "../../Components/Header/MainHeader";
import { MapPin, Home, Phone, Building, User } from 'lucide-react';
import firebase from '../../Database/config';
import countyData from '../../data/county.json';
import { db } from '../../Database/FindProperty/config';
import banner from "../../assets/r-propertybanner.png"

const FindRental = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    county: '',
    constituency: ''
  });
  const [constituencies, setConstituencies] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);

  // Fetch properties from Firebase
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const snapshot = await db
          .collection('Users')
          .get();

        const propertiesList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProperties(propertiesList);
        setFilteredProperties(propertiesList);
      } catch (error) {
        console.error("Error fetching properties:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  // Update constituencies when county changes
  useEffect(() => {
    if (filters.county) {
      const selectedCounty = countyData.County.find(
        county => county.county_name === filters.county
      );
      if (selectedCounty) {
        setConstituencies(selectedCounty.constituencies);
        setFilters(prev => ({ ...prev, constituency: '' }));
      }
    } else {
      setConstituencies([]);
    }
  }, [filters.county]);

  // Apply filters
  useEffect(() => {
    let filtered = [...properties];
    
    if (filters.county) {
      filtered = filtered.filter(property => property.county === filters.county);
    }
    
    if (filters.constituency) {
      filtered = filtered.filter(property => property.constituency === filters.constituency);
    }
    
    setFilteredProperties(filtered);
  }, [filters, properties]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className='flex flex-col min-h-screen'>
      <MainHeader />
      
      {/* Banner Section */}
        <div 
        className="relative bg-cover bg-center text-white py-16"
        style={{ backgroundImage: `url(${banner})` }}
        >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative container mx-auto px-4">
            <div className="max-w-3xl">
            <h1 className="text-4xl font-bold mb-4">Find Your Perfect Property</h1>
            <p className="text-lg mb-6">Browse through our extensive collection of properties across different counties and constituencies.</p>
            <br />
            <br />
            <h1 className="text-4xl font-bold mb-4">Do You Own A Property?</h1>
            <p className="text-lg mb-6">Join thousands of property owners in showcasing and maintaining their homes effortlessly!</p>
            <a 
                href="https://r-property.rekebisha.com" 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-white text-black px-6 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors"
            >
                Register Now!
            </a>
            </div>
        </div>
        </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Filters Sidebar */}
          <div className="w-full md:w-1/4">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">Filters</h2>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">County</label>
                  <select
                    name="county"
                    value={filters.county}
                    onChange={handleFilterChange}
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                  >
                    <option value="">All Counties</option>
                    {countyData.County.map((county) => (
                      <option key={county.county_name} value={county.county_name}>
                        {county.county_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Constituency</label>
                  <select
                    name="constituency"
                    value={filters.constituency}
                    onChange={handleFilterChange}
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                    disabled={!filters.county}
                  >
                    <option value="">All Constituencies</option>
                    {constituencies.map((constituency) => (
                      <option key={constituency.constituency_name} value={constituency.constituency_name}>
                        {constituency.constituency_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Property Listings */}
          <div className="w-full md:w-3/4">
            {loading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black"></div>
              </div>
            ) : filteredProperties.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredProperties.map((property) => (
                  <div key={property.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                    <div className="p-6">
                      <div className="flex items-center gap-2 mb-2">
                        <Building className="w-5 h-5 text-gray-600" />
                        <h3 className="font-semibold">{property.company || 'Property Owner'}</h3>
                      </div>
                      
                      <div className="space-y-2 text-gray-600">
                        <div className="flex items-center gap-2">
                          <User className="w-4 h-4" />
                          <span>{property.name}</span>
                        </div>
                        
                        <div className="flex items-center gap-2">
                          <MapPin className="w-4 h-4" />
                          <span>{property.county}, {property.constituency}</span>
                        </div>
                        
                        <div className="flex items-center gap-2">
                          <Phone className="w-4 h-4" />
                          <span>{property.phone}</span>
                        </div>
                      </div>
                      
                      <button 
                        onClick={() => window.location.href = `tel:${property.phone}`}
                        className="mt-4 w-full bg-black text-white py-2 rounded-md hover:bg-gray-800 transition-colors"
                      >
                        Contact Owner
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-64 text-gray-500">
                <Home className="w-16 h-16 mb-4" />
                <p>No properties found matching your criteria.</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <MainFooter />
    </div>
  );
};

export default FindRental;